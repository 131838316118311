import React, { Component } from 'react';

class Header extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <>
          <div className="main-header">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div id="sticky-header" className="nav-menu">
                    <div className="header-logo">
                      <a href="/"><img src="assets/img/light-logo.png" alt="" /></a>
                      <a className="main_sticky" href="/"><img src="assets/img/light-logo.png" alt="" /></a>
                    </div>
                    <div className="heder-menu">
                      <ul>
                        <li><a href="#Home">Home </a></li>
                        <li><a href="#Token">Token </a></li>
                        <li><a href="#About">About </a></li>
                        <li><a href="#Service">Service</a></li>
                        <li><a href="#Roadmap">Roadmap </a></li>
                      </ul>
                      <div className="menu-button">
                        <a href="https://metamask.io/download/" target="_blank">MetaMask</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mobile-menu-area d-sm-block d-md-block d-lg-none ">
            <div className="mobile-menu">
              <nav className="itsoft_menu">
                <ul className="nav_scroll">
                  <li><a href="#Home">Home </a></li>
                  <li><a href="#Token">Token </a></li>
                  <li><a href="#About">About </a></li>
                  <li><a href="#Service">Service</a></li>
                  <li><a href="#Roadmap">Roadmap </a></li>
                </ul>
              </nav>
            </div>
          </div>          
        </>        
      );
    }
  }

  export default Header;