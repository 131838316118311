import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class Footer extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      const { t } = this.props;
      return (
        <>
          <div className="footer-area">
            <div className="container">
              <div className="row footer-bottom">
                <div className="col-lg-12">
                  <div className="copy-left-box">
                    <div className="copy-left-title">
                      <h3>{t('footer.Copyright_Text')}</h3>
                    </div>
                  </div>
                </div>                
              </div>
            </div>
          </div>

          <div className="scroll-area">
            <div className="top-wrap">
              <div className="go-top-btn-wraper">
                <div className="go-top go-top-button">
                  <i className="fa fa-angle-double-up" aria-hidden="true"></i>
                  <i className="fa fa-angle-double-up" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }

  //export default Footer;
  export default withTranslation()(Footer);