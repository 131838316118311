import React, { Component } from 'react';
import Frame from "../../components/frame";
import {withRouter} from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Mintinghd from "../../contracts/Mintinghd.json";
import TokenAprove from "../../contracts/TokenAprove.json";
import getWeb3 from "../../getWeb3";
import { BigNumber } from "bignumber.js";
// Internationalization
import { withTranslation } from 'react-i18next';
import TopHeader from '../../components/TopHeader';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      web3: null,
      accounts: null,
      contract: null,
      contractAddress: null,
      YourAddress:'-----------',
      YourAddressfull:null,
      sponsor_address: (this.props.match.params.referral != 'undefined') ? this.props.match.params.referral : '',
      userInfo:{},      
      user_referer:'-----------',
      userBalanceOf:0,
      currentPrice:0,
      currentSellPrice:0,
      balance:0,
      balanceEthVal:0,
      amount:'',
      totalAmount:'',
      availableStake:0,
      availableStakeBnb:0,
      staking:0,
      totalTokenSupply:0,
      table_data:[],
      per:0,
      isBuy: true,
      TokenAmount:'',
      sponsorList:[],
      getUserTotalDividends:null,
      get_mintinghd_amount:0,
      get_bnb_amount:0,
      UsdToBnb:0,
      referrerBonus:0,
      current_timestamp: Math.floor(Date.now() / 1000),
      lockin_period_end_time: 90*24*3600,
      stake_amount:'',
      chkAllowance:''
    }
  }

  componentDidMount = async () => {
    try {
      // Get network provider and web3 instance.
      const web3 = await getWeb3();

      // Use web3 to get the user's accounts.
      setInterval(async()=>{
        const accounts = await web3.eth.getAccounts();
       
        if(this.state.accounts !== accounts[0]){
        // Get the contract instance.
        const networkId = await web3.eth.net.getId();
        //alert(networkId);
        //if(networkId !== 56) throw "Please connect Mainnet"; 
        const deployedNetwork = Mintinghd.networks;
        const instance = new web3.eth.Contract(
          Mintinghd.abi,
          deployedNetwork && Mintinghd.address,
        );
        
        const TokenAprove_instance = new web3.eth.Contract(
          TokenAprove.abi,
          deployedNetwork && TokenAprove.address,
        );
        
        this.setState({ web3, accounts:accounts[0], contract: instance, contractAddress:Mintinghd.address, 
          TokenAprove_contract: TokenAprove_instance, TokenAprove_contractAddress:TokenAprove.address}, this.fetchData);
        }
     },1000);

    } catch (error) {
      // Catch any errors for any of the above operations.
      // alert(
      //   error
      // );
      this.setState({networkMain:false});
      console.error(error);
    }
    this.usdPriceUpdate();
  };

  usdPriceUpdate = async() => {
    fetch('https://api.binance.com/api/v3/ticker/price?symbol=BNBUSDT')
    .then(response => response.json())
    .then(data => {
      //console.log(data);
      this.setState({UsdToBnb:data.price});
    }
    
    );
  }

  fetchData = async() => {
    try {

      const { accounts, contract } = this.state;

      //console.log(this.state.contractAddress);

      let YourAddress = accounts.slice(0, 10) + '.....' + accounts.slice(accounts.length-5, accounts.length);
      let YourAddressfull = accounts;
      console.log('userInfo');
      let userInfo = await contract.methods.users(accounts).call();
      //console.log(userInfo);
      let user_referer = '---------';
      let sponsor_address = this.state.sponsor_address;
      if(userInfo.referrer && !userInfo.referrer.includes('0x000')){
        user_referer = userInfo.referrer;
        user_referer = user_referer.slice(0, 10) + '.....' + user_referer.slice(user_referer.length-5, user_referer.length);
        sponsor_address = userInfo.referrer;      
      }else{      
        sponsor_address = (this.props.match.params.referral != undefined) ? this.props.match.params.referral : '';
        if(sponsor_address == undefined){
          sponsor_address = '';
        }
      }
      

      let referrerBonus = this.state.referrerBonus;
      if(userInfo.referrerBonus){
        referrerBonus = userInfo.referrerBonus;
        referrerBonus = (referrerBonus / 1e9);
      }

      // console.log('---------userInfo---------');
      // console.log(userInfo);

      let userBalanceOf = await contract.methods.userBalanceOf(accounts).call();
      userBalanceOf = (userBalanceOf / 1e9);
      

      let balance = await this.state.web3.eth.getBalance(accounts);
      let balanceEthVal = this.state.web3.utils.fromWei(balance, 'ether');

      let availableStake = userBalanceOf;
      let availableStakeBnb = userBalanceOf/1e9;

      let staking = await contract.methods.getUserTotalStake(accounts).call();
      staking = (staking / 1e9);

      let totalTokenSupply = await contract.methods.totalTokenSupply().call();
      totalTokenSupply = (totalTokenSupply / 1e9);
      totalTokenSupply = parseFloat(totalTokenSupply).toFixed(2);  

      let per = ((totalTokenSupply / 100000000000000) * 100);
      per = parseFloat(per).toFixed(2);

      let getUserTotalDividends = await contract.methods.getUserTotalDividends(accounts).call();
      getUserTotalDividends = (getUserTotalDividends / 1e9);
      getUserTotalDividends = parseFloat(getUserTotalDividends).toFixed(5);

      let current_timestamp = Math.floor(Date.now() / 1000);
      
      let chkAllowance = await contract.methods.chkAllowance(accounts, Mintinghd.address).call();
      chkAllowance = (chkAllowance / 1e9);

      console.log(chkAllowance);

      this.setState({
        userInfo,
        user_referer:user_referer,
        sponsor_address:sponsor_address,
        YourAddress,
        YourAddressfull,
        userBalanceOf,
        balance,
        balanceEthVal,
        availableStake,
        availableStakeBnb,
        staking,
        totalTokenSupply,
        per,
        getUserTotalDividends,
        referrerBonus,
        current_timestamp,
        chkAllowance,
        loading:false
      },async()=>{
        const { accounts, contract } = this.state;
        let getUserDetails = await contract.methods.getUserDetails(accounts).call();
        let table_length = parseInt(getUserDetails[0]);
        let table_data = [];     

        if(table_length>0){
          for(let i=0;i<table_length;i++){
            let stake = await contract.methods.stakeOf(accounts,i).call();
            stake = (stake /1e9);
      
            let roi = await contract.methods.getUserDividend(accounts,i).call();
            let roi_value = roi;
            roi = (roi /1e9);
    
            let userStake_start = await contract.methods.userStake_start(accounts,i).call();
            let start_time = parseInt(userStake_start)*1000;
            let expiry_time = start_time + parseInt(this.state.lockin_period_end_time)*1000;
            let remainingDayTime = expiry_time - this.state.current_timestamp*1000;
            let remainingDays = Math.floor(remainingDayTime / (1000 * 3600 * 24));
    
            let roi_data = {index:i,stake:stake,roi:roi,userStake_start:userStake_start,remainingDays:remainingDays};
            if(roi_value>0){
              table_data.push(roi_data);
            }
          }
        }  
      
        this.setState({table_data});

        let sponsorList = [];
        let count = 0;
        for(let i=0;i<4;i++){
          let referral_stage = await contract.methods.referral_stage(accounts,i).call();
          let _investment = referral_stage._investment/1e9;
          let percentage_amount = referral_stage._bonus/1e9;
          _investment = parseFloat(_investment).toFixed(5);
          percentage_amount = parseFloat(percentage_amount).toFixed(5);
          let _noOfUser = referral_stage._noOfUser;
          
          if(i == 0){
            count = _noOfUser;
          }
          let status = '';
          let requireText = '';
          if((i>=2 && i<5) && count<4){
            status = 'Unqualified';
            requireText = 'require '+4 + ' direct';
          }
          sponsorList.push({requireText:requireText,status:status,level:i+1,_investment:_investment,_noOfUser:_noOfUser,percentage_amount:percentage_amount});
        }
        
        this.setState({sponsorList});
      })

    } catch(error){
      console.log('error');
      console.log(error);
    }
    
  }

  doBuy= async () => {
    console.log('do buy');
    //const weiValue = this.state.web3.utils.toWei('1', 'ether');
    //const etherValue = this.state.web3.utils.fromWei('1000000000000000000', 'ether');
    const { accounts, contract } = this.state;
    let sponsor_address = this.state.sponsor_address;
    
    if(!sponsor_address){
      this.props.enqueueSnackbar("Sponsor Address is required!",{ variant: 'error' })
      this.setState({sponsor_addressError:true});
      return false;
    }

    if(!this.state.amount){
      this.props.enqueueSnackbar("Amount is required!",{ variant: 'error' })
      this.setState({amountError:true});
      return false;
    }
    
    let balance = await this.state.web3.eth.getBalance(this.state.accounts);
    let balanceEthVal = this.state.web3.utils.fromWei(balance, 'ether');
    let amountBNB = this.state.amount;
    let TokenAmount = parseInt(this.state.TokenAmount);
    let get_mintinghd_amount = parseInt(this.state.get_mintinghd_amount);
    if(sponsor_address){
      //if(balanceEthVal >= amountBNB){
      if(get_mintinghd_amount >= 100){
        try {
          this.setState({loading:true});
          this.state.contract.events.BuyToken((err, eventResult) => {
            console.log('eventResult',eventResult);
            if (!err) {
              let uerAddress = this.state.accounts;
              if(eventResult.returnValues[0] == uerAddress){
                this.fetchData();
                this.setState({loading:false});
                this.props.enqueueSnackbar("Buy Token Successfully!",{ variant: 'success' });                
              }              
            }else{
              console.log(err);
              this.setState({loading:false});
              this.props.enqueueSnackbar('Some Network Error Occurred!');              
            }
          });

          let weiValue = this.state.web3.utils.toWei(amountBNB.toString(), 'ether');
          let invest = await this.state.contract.methods.buyToken(sponsor_address).send(
            {
              from: this.state.accounts,
              value:weiValue
            }
          );
          //console.log(invest);
          setTimeout(() => {
            if(this.state.loading){
              window.location.reload(false);
            }
          }, 10000);
        }
        catch(err) {
          console.log(err);
          this.setState({loading:false});
          if (err.message.includes("User denied transaction signature")) {
            // handle the "error" as a rejection
            this.props.enqueueSnackbar(err.message,{ variant: 'error' });
          }else{
            this.props.enqueueSnackbar(err,{ variant: 'error' });
          }          
        }
      }else{
        this.setState({loading:false});
        this.props.enqueueSnackbar("Minimum 100 Token is required!",{ variant: 'error' });          
      }

      // }else{
      //   this.setState({loading:false});
      //   this.props.enqueueSnackbar("Insufficient BNB Balance!",{ variant: 'error' });          
      // }        
    }else{
      this.setState({loading:false});
      this.props.enqueueSnackbar("Please enter sponsor address!",{ variant: 'error' });        
    }
  }

  doSell= async () => {
    //const weiValue = this.state.web3.utils.toWei('1', 'ether');
    //const etherValue = this.state.web3.utils.fromWei('1000000000000000000', 'ether');
    const { accounts, contract } = this.state;
    let balance = await this.state.web3.eth.getBalance(this.state.accounts);
    let balanceEthVal = this.state.web3.utils.fromWei(balance, 'ether');
    let TokenAmount = this.state.TokenAmount;
    let availableStake = this.state.availableStake;
    // let sponsor_address = this.state.sponsor_address;
    
    // if(!sponsor_address){
    //   this.props.enqueueSnackbar("Sponsor Address is required!",{ variant: 'error' })
    //   this.setState({sponsor_addressError:true});
    //   return false;
    // }

    if(!this.state.TokenAmount){
      this.props.enqueueSnackbar("Token Amount is required!",{ variant: 'error' })
      this.setState({amountError:true});
      return false;
    }

    if(!availableStake){
      this.props.enqueueSnackbar("Insufficient Token Balance!!",{ variant: 'error' })
      this.setState({amountError:true});
      return false;
    }    
    
    //if(sponsor_address){
      if(parseInt(availableStake) > parseInt(TokenAmount)){
        try {
          this.setState({loading:true});
          this.state.contract.events.SellToken((err, eventResult) => {
            console.log('eventResult',eventResult);
            if (!err) {
              let uerAddress = this.state.accounts;
              if(eventResult.returnValues[0] == uerAddress){
                this.fetchData();
                this.setState({loading:false});
                this.props.enqueueSnackbar("Sell Token Successfully!",{ variant: 'success' });                
              }              
            }else{
              console.log(err);
              this.setState({loading:false});
              this.props.enqueueSnackbar('Some Network Error Occurred!');              
            }
          });

          TokenAmount = (TokenAmount * 1e9).toString();
          let sell = await this.state.contract.methods.sellToken(TokenAmount).send(
            {
              from: this.state.accounts,
              value:0
            }
          );
          //console.log(sell);
          setTimeout(() => {
            if(this.state.loading){
              window.location.reload(false);
            }
          }, 10000);
        }
        catch(err) {
          this.setState({loading:false});
          if (err.message.includes("User denied transaction signature")) {
            // handle the "error" as a rejection
            this.props.enqueueSnackbar(err.message,{ variant: 'error' });
          }else{
            this.props.enqueueSnackbar(err,{ variant: 'error' });
          }
        }
      }else{
        this.setState({loading:false});
        this.props.enqueueSnackbar("Insufficient Token Balance!",{ variant: 'error' });          
      }        
    // }else{
    //   this.setState({loading:false});
    //   this.props.enqueueSnackbar("Please enter sponsor address!",{ variant: 'error' });        
    // }
  }

  doStake_old = async () => {
    if(parseInt(this.state.userBalanceOf) < 100){
      this.props.enqueueSnackbar("Minimum 100 Token Balance is required!",{ variant: 'error' });
      return false;
    }
    try {
      this.setState({loading:true});
      this.state.contract.events.StakeToken((err, eventResult) => {
        console.log('eventResult',eventResult);
        if (!err) {
          let uerAddress = this.state.accounts;
          if(eventResult.returnValues[0] == uerAddress){
            this.fetchData();
            this.setState({loading:false});
            this.props.enqueueSnackbar("Token Stake Successfully!",{ variant: 'success' });                
          }              
        }else{
          console.log(err);
          this.setState({loading:false});
          this.props.enqueueSnackbar('Some Network Error Occurred!');              
        }
      });
      
      //let weiValue = this.state.web3.utils.toWei(this.state.userBalanceOf.toString(), 'ether');
      let createStake = await this.state.contract.methods.createStake(this.state.userBalanceOf*1e9).send(
        {
          from: this.state.accounts,
          value:0
        }
      );
      //console.log(createStake);
      setTimeout(() => {
        if(this.state.loading){
          window.location.reload(false);
        }
      }, 10000);
    }
    catch(err) {
      this.setState({loading:false});
      if (err.message.includes("User denied transaction signature")) {
        // handle the "error" as a rejection
        this.props.enqueueSnackbar(err.message,{ variant: 'error' });
      }else{
        this.props.enqueueSnackbar(err,{ variant: 'error' });
      }          
    } 
  }

  doStake = async () => {
    if(parseInt(this.state.userBalanceOf) < 1){
      this.props.enqueueSnackbar("Minimum 1 Token Balance is required!",{ variant: 'error' });
      return false;
    }
    if(parseInt(this.state.stake_amount) > parseInt(this.state.availableStake)){
      this.props.enqueueSnackbar("Insufficient Balance!",{ variant: 'error' });
      return false;
    }
    try {
      this.setState({loading:true});
      this.state.contract.events.StakeToken((err, eventResult) => {
        console.log('eventResult',eventResult);
        if (!err) {
          let uerAddress = this.state.accounts;
          if(eventResult.returnValues[0] == uerAddress){
            this.fetchData();
            this.setState({loading:false});
            this.props.enqueueSnackbar("Token Stake Successfully!",{ variant: 'success' });                
          }              
        }else{
          console.log(err);
          this.setState({loading:false});
          this.props.enqueueSnackbar('Some Network Error Occurred!');              
        }
      });
      
      //let weiValue = this.state.web3.utils.toWei(this.state.userBalanceOf.toString(), 'ether');
      let createStake = await this.state.contract.methods.createStake(this.state.stake_amount*1e9,this.state.sponsor_address).send(
        {
          from: this.state.accounts,
          value:0
        }
      );
      console.log(createStake);
      setTimeout(() => {
        if(this.state.loading){
          window.location.reload(false);
        }
      }, 10000);
    }
    catch(err) {
      this.setState({loading:false});
      if (err.message.includes("User denied transaction signature")) {
        // handle the "error" as a rejection
        this.props.enqueueSnackbar(err.message,{ variant: 'error' });
      }else{
        this.props.enqueueSnackbar(err,{ variant: 'error' });
      }          
    } 
  }

  doUnstake = async (index,start_time) => {
    let currentTime = Math.floor(Date.now() / 1000);
    let end_time = parseInt(start_time) + this.state.lockin_period_end_time;
    if(end_time > currentTime){
      this.props.enqueueSnackbar('You cannot unstake right now as it is locked.',{ variant: 'error' });
      return false;
    }

    try {
      this.setState({loading:true});
      this.state.contract.events.UnStake((err, eventResult) => {
        console.log('eventResult',eventResult);
        if (!err) {
          let uerAddress = this.state.accounts;
          if(eventResult.returnValues[0] == uerAddress){
            this.fetchData();
            this.setState({loading:false});
            this.props.enqueueSnackbar("Token Unsake Successfully!",{ variant: 'success' });                
          }              
        }else{
          console.log(err);
          this.setState({loading:false});
          this.props.enqueueSnackbar('Some Network Error Occurred!');              
        }
      });
   
      let removeStake = await this.state.contract.methods.removeStake((index).toString()).send(
        {
          from: this.state.accounts,
          value:0
        }
      );
      //console.log(removeStake);
      setTimeout(() => {
        if(this.state.loading){
          window.location.reload(false);
        }
      }, 10000);
    }
    catch(err) {
      this.setState({loading:false});
      if (err.message.includes("User denied transaction signature")) {
        // handle the "error" as a rejection
        this.props.enqueueSnackbar(err.message,{ variant: 'error' });
      }else{
        this.props.enqueueSnackbar(err,{ variant: 'error' });
      }          
    }
  }  

  doCalculation=(e,amount_type,type=null)=>{
    var x = new BigNumber(e.target.value);
    if(type == 'buy'){
      if(amount_type==0){
        this.setState({
          amount:e.target.value,
          totalAmount:(x.isNaN() ? 0 : x.multipliedBy(this.state.currentPrice))
        });
      }else{
        var y = new BigNumber(this.state.currentPrice);
        this.setState({
          amount:(x.isNaN() ? 0 : x.dividedBy(y)),
          totalAmount:(x.isNaN() ? 0 : x)
        });
      }
    }

    if(type == 'sell'){
      if(amount_type==0){
        this.setState({
          amount:e.target.value,
          totalAmount:(x.isNaN() ? 0 : x.multipliedBy(this.state.currentPrice))
        });
      }else{
        var y = new BigNumber(this.state.currentPrice);
        this.setState({
          amount:(x.isNaN() ? 0 : x.dividedBy(y)),
          totalAmount:(x.isNaN() ? 0 : x)
        });
      }
    }    
  } 

  doSwap = () => {
    if(this.state.isBuy){
      this.doBuy();
    }else{
      this.doSell();
    }
  }

  doCalculationApi = async(value,type) => {
    let amount = this.state.amount;    
    let TokenAmount = this.state.TokenAmount;
    console.log(type);
    if(type == 'amount'){
      console.log(amount);
      if(amount){
        amount = this.state.web3.utils.toWei(amount.toString(), 'ether');
        let calculateTokensReceived = await this.state.contract.methods.calculateTokensReceived(amount).call();
        console.log('get token');
        calculateTokensReceived = calculateTokensReceived/1e9;
        console.log(calculateTokensReceived);
        this.setState({TokenAmount:calculateTokensReceived});
      }else{
        this.props.enqueueSnackbar("Amount is required!",{ variant: 'error' });
      }
      
    }else{
      console.log(TokenAmount);
      if(TokenAmount){
        let calculateBnbReceived = 0;
        if(this.state.totalTokenSupply > 0){
          TokenAmount = (TokenAmount * 1e9).toString();
          //TokenAmount = this.state.web3.utils.toWei(TokenAmount.toString(), 'ether');
          calculateBnbReceived = await this.state.contract.methods.calculateBnbReceived(TokenAmount).call();
          calculateBnbReceived = calculateBnbReceived/1e18;
        }      
        console.log('get bnb');
        console.log(calculateBnbReceived);        
        this.setState({amount:calculateBnbReceived});
      }else{
        this.props.enqueueSnackbar("Token Amount is required!",{ variant: 'error' });
      }
      
    }
  }

  getMintinghdApi = async(value,type) => {
    let amount = this.state.amount;
    if(amount){
      amount = this.state.web3.utils.toWei(amount.toString(), 'ether');
      let calculateTokensReceived = await this.state.contract.methods.calculateTokensReceived(amount).call();
      console.log('get token');
      calculateTokensReceived = calculateTokensReceived/1e9;
      console.log(calculateTokensReceived);
      this.setState({get_mintinghd_amount:calculateTokensReceived});
    }else{
      this.setState({get_mintinghd_amount:0});
    }
  }

  getBnbApi = async(value,type) => {
    let TokenAmount = this.state.TokenAmount;
    if(TokenAmount){
      let calculateBnbReceived = 0;
      if(this.state.totalTokenSupply > 0){
        TokenAmount = (TokenAmount * 1e9).toString();
        //TokenAmount = this.state.web3.utils.toWei(TokenAmount.toString(), 'ether');
        calculateBnbReceived = await this.state.contract.methods.calculateBnbReceived(TokenAmount).call();
        calculateBnbReceived = calculateBnbReceived/1e18;
      }
      console.log('get bnb');
      console.log(calculateBnbReceived);        
      this.setState({get_bnb_amount:calculateBnbReceived});
    }else{
      this.setState({get_bnb_amount:0});
    }
  }

  doWithdraw= async () => {
    try {
      this.setState({loading:true});
      this.state.contract.events.Withdrawn((err, eventResult) => {
        console.log('eventResult',eventResult);
        if (!err) {
          let uerAddress = this.state.accounts;
          if(eventResult.returnValues[0] == uerAddress){
            this.fetchData();
            this.setState({loading:false});
            this.props.enqueueSnackbar("Reward Withdrawn Successfully!",{ variant: 'success' });                
          }
        }else{
          console.log(err);
          this.setState({loading:false});
          this.props.enqueueSnackbar('Some Network Error Occurred!');
        }
      });

      let withdrawReward = await this.state.contract.methods.withdrawReward(this.state.accounts).send(
        {
          from: this.state.accounts,
          value:0
        }
      );
      //console.log(withdrawReward);
      setTimeout(() => {
        if(this.state.loading){
          window.location.reload(false);
        }
      }, 10000);
    }
    catch(err) {
      this.setState({loading:false});
      if (err.message.includes("User denied transaction signature")) {
        // handle the "error" as a rejection
        this.props.enqueueSnackbar(err.message,{ variant: 'error' });
      }else{
        this.props.enqueueSnackbar(err,{ variant: 'error' });
      }
    }
  }

  doApprove = async () => {
    try {
      this.setState({loading:true});
      let amount = (this.state.userBalanceOf*1e9);
      let approve = await this.state.TokenAprove_contract.methods.approve(Mintinghd.address, amount).send(
        {
          from: this.state.accounts,
          value:0
        }
      );
      console.log(approve);
      this.fetchData();
    }catch(err) {
      console.log(err);
    }
  }

  render() {
    const { t } = this.props;
    return (
      <Frame withHeader={false} withFooter={true} currentPrice={this.state.currentPrice}>
        <TopHeader isHome={true} address={this.state.accounts} currentPrice={this.state.currentPrice} />
        {this.state.loading ? (
            <div className="loader-container">
              <div className="loader">
                <span>Loading...</span>
                <div className="progressbar"></div>
              </div>
            </div>
        ) : (null)}

        <div className="slider-area d-flex align-items-center" id="Home">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-lg-6 col-md-6">
                <div className="single-slider-box">
                  <div className="slider-content">
                    <div className="slider-title">
                      <h3><span>!</span>{t('mainContent.Banner_Info')}</h3>
                      <h1>{t('mainContent.Banner_Heading')}</h1>
                      <p>{t('mainContent.Banner_Desc')}</p>
                    </div>
                  </div>
                  <div className="slider-button">
                    <a href={`https://bscscan.com/address/${Mintinghd.address}`} target="_blank">{t('mainContent.Bsc_Scan_Text')}</a>
                  </div>
                  
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="single-slider-box">
                  <div className="slider-thumb bounce-animate ">
                    <img className="slider_img" src="assets/img/22.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="buy_box" id="Token">
          <div className="container">
            <div className="white_box" style={{display:'none'}}>
              <div className="row">
                <div className="col-md-4 col-sm-4 col-lg-4">
                  <div className="price_inner">
                    <h4>{t('mainContent.Buy_Price_Bnb_Text')}</h4>
                    <h3>{parseFloat(this.state.currentPrice).toFixed(7)}</h3>
                    <img src="assets/img/bnb_icon.png" alt="" />
                  </div>
                </div>
                <div className="col-md-4 col-sm-4 col-lg-4">
                  <div className="price_inner">
                    <h4>{t('mainContent.Sell_Price_Bnb_Text')}</h4>
                    <h3>{parseFloat(this.state.currentSellPrice).toFixed(7)}</h3>
                    <img src="assets/img/bnb_icon.png" alt="" />
                  </div>
                </div>
                <div className="col-md-4 col-sm-4 col-lg-4">
                  <div className="price_inner">
                    <h4>{t('mainContent.Usd_Price_Text')}</h4>
                    <h3>{parseFloat(this.state.currentPrice*this.state.UsdToBnb).toFixed(7)}</h3>
                    <img src="assets/img/usd_icon.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="copy_link">
              <span>{this.state.YourAddress}</span>
              <CopyToClipboard text={`https://mintinghd.com/${this.state.YourAddressfull}`}
                onCopy={() => this.props.enqueueSnackbar(`${t('mainContent.Copy_Link_Success')}`,{ variant: 'info' })
                }>
                <button className="link" title={t('mainContent.Copy_Referral_Link_Text')}>{t('mainContent.Copy_Link_Text')}</button>
              </CopyToClipboard>
            </div>
          </div>
        </div>

        <div className="service-area" id="About">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="about-thumb-box">
                  <div className="about-thumb">
                    <img className="about_img" src="assets/img/about-img.png" alt="" />
                    <div className="shap-img rotateme">
                      <img src="assets/img/about-shape.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="single-about-box">
                  <div className="about-content">
                    <div className="about-title">
                      <h4>{t('mainContent.Platform_Info')}</h4>
                      <h2>{t('mainContent.Platform_Title')}</h2>
                    </div>
                    <div className="about-text">
                      <div className="about-num">
                        <span>01</span>
                      </div>
                      <h5>{t('mainContent.Platform_SubTitle1')}</h5>
                      <p>{t('mainContent.Platform_SubDesc1')}</p>
                    </div>
                    <div className="about-text">
                      <div className="about-num">
                        <span>02</span>
                      </div>
                      <h5>{t('mainContent.Platform_SubTitle2')}</h5>
                      <p>{t('mainContent.Platform_SubDesc2')}</p>
                    </div>
                    <div className="about-text">
                      <div className="about-num upper">
                        <span>03</span>
                      </div>
                      <h5>{t('mainContent.Platform_SubTitle3')}</h5>
                      <p>{t('mainContent.Platform_SubDesc3')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p className="mt-4" style={{color:'white'}}>
            DAO is the abbreviation of Decentralized Autonomous Organization in English. 
            It is an organizational form derived from the core idea of ​​blockchain 
            (co-creation, co-construction, co-governance, and sharing of collaborative
             behaviors spontaneously generated by groups that reach the same consensus).
               It is a subsidiary product from the blockchain.  It solves the trust problem between people.
            </p>
            <p style={{color:'white'}}>
            DAO is an evolutionary version of the company’s organizational form.
              A revolutionary evolution in the history of human collaboration. 
               Its essence is a form of blockchain technology application.               
              HopeDao is an autonomous community of hope, which is made by ordinary
               people who yearn for success. HD is an opportunity and hope for the
                world to overturn on the blockchain. With hope, there is future, 
                and with hope, it will be brilliant!
            </p>
            <p style={{color:'white'}}>
            HopeDao, or HD for short, has a reward token that automatically distributes 
            Doge coins.  $HD offers a unique, new and exciting way to safely invest
             your finances.  Just love and hold for a long time to reach a high consensus 
             to gain the value of your $HD.  We combine HD trends with actual token economics
              so you can live out your coveted dreams and be richer than you ever imagined.
            </p>
            <p style={{color:'white'}}>
            HopeDao rewards long-term holders, hoping that the autonomous community 
            will bring its own million traffic. HopeDao is proud to provide transparency
             and fairness to the community. It is based on the token on the Binance Smart 
             Chain (BSC) chain. HopeDao is the BSC ecosystem.  Here comes the most revolutionary mechanism.
            </p>
          </div>
        </div>
        
        <div className="about-area">
          <div className="container">
            <div className="md_container">
              <div className="row g-3">
              <div className="col-md-6 col-sm-6 col-lg-6">
                <div className="mywallet_box">
                  <div className="mywallet_body">
                    <h3>{t('mainContent.My_Wallet')}</h3>
                    <h2 className="my_ballance_amount"><img src="assets/img/logo_icon.png" alt="" />{parseFloat(this.state.availableStake).toFixed(7)} {t('mainContent.Mintinghd')}</h2>
                    <p className="smwallet_text">{parseFloat(this.state.availableStake*this.state.currentSellPrice).toFixed(7)} {t('mainContent.BNB')} (${parseFloat(this.state.availableStake*this.state.currentSellPrice*this.state.UsdToBnb).toFixed(2)})</p>
                    <div className="text-center">
                      {/* <button className="buy_btn" disabled={this.state.loading} onClick={this.doStake}>{t('mainContent.Stake')}</button> */}
                      {parseInt(this.state.chkAllowance) > 0 ? (
                        <button className="buy_btn" data-toggle="modal" data-target="#buyModal" onClick={()=>this.setState({isBuy:true})}>{t('mainContent.Stake')}</button>
                      ) : (
                        <button className="buy_btn" disabled={this.state.loading} onClick={()=>this.doApprove()}>Approve</button>
                      )}
                    </div>
                  </div>
                </div>
              </div>


              <div className="col-md-6 col-sm-6 col-lg-6">
                <div className="mywallet_box">
                  
                  <div className="mywallet_body">
                    <h3>{t('mainContent.My_Rewards')}</h3>
                    <div className="row g-2">
                    <div className="col-md-6 col-sm-6 col-lg-6">
                      <h2 className="my_ballance_amount"><img src="assets/img/logo_icon.png" alt="" />{parseFloat(this.state.getUserTotalDividends).toFixed(7)}</h2>
                      <p className="smwallet_text">{parseFloat(this.state.getUserTotalDividends*this.state.currentSellPrice).toFixed(7)} {t('mainContent.BNB')} (${parseFloat(this.state.getUserTotalDividends*this.state.currentSellPrice*this.state.UsdToBnb).toFixed(2)})</p>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-6">
                      <h2 className="my_ballance_amount"><img src="assets/img/logo_icon.png" alt="" />{parseFloat(this.state.referrerBonus).toFixed(7)}</h2>
                      <p className="smwallet_text">{t('mainContent.From_Referrals')}</p>
                    </div>
                    </div>
                    <div className="text-center">
                      <button className="buy_btn" disabled={this.state.loading} onClick={this.doWithdraw}>{t('mainContent.Withdraw')}</button>
                    </div>
                  </div>                  
                </div>
              </div>

              </div>
              {/* <br /> */}
              <div className="row g-3" style={{display:'none'}}>
                <div className="col-md-6 col-sm-6 col-lg-6">
                  <div className="mywallet_box">                  
                    <div className="mywallet_body">
                      <h3><span>$</span> {t('mainContent.Buy')} {t('mainContent.Mintinghd')}</h3>
                      <h2 className="my_ballance_amount"><img className="buy_sele_logo" src="assets/img/bnb_coin.png" alt="" /> {parseFloat(this.state.currentPrice).toFixed(7)}</h2>
                      <p className="smwallet_text">${parseFloat(this.state.currentPrice*this.state.UsdToBnb).toFixed(2)}</p>
                      <div className="mt-4">
                        <input placeholder={t('mainContent.Buy_Input')} className="cus_input" type="number" 
                          value={this.state.amount || ''}
                          onChange={(e)=>this.setState({amount:e.target.value})}
                          onBlur={(e)=>this.getMintinghdApi(e.target.value,'amount')}
                        />
                      </div>
                      <button data-toggle="modal" data-target="#buyModal" className="buy_btn" onClick={()=>this.setState({isBuy:true})}>{t('mainContent.Buy')}</button>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-sm-6 col-lg-6">
                  <div className="mywallet_box">
                    <div className="mywallet_body">
                      <h3><span>$</span> {t('mainContent.Sell')} {t('mainContent.Mintinghd')}</h3>
                      <h2 className="my_ballance_amount"><img className="buy_sele_logo" src="assets/img/bnb_coin.png" alt="" />{parseFloat(this.state.currentSellPrice).toFixed(7)}</h2>
                      <p className="smwallet_text">${parseFloat(this.state.currentSellPrice*this.state.UsdToBnb).toFixed(2)}</p>
                      <div className="mt-4">
                        <input placeholder={t('mainContent.Sell_Input')} className="cus_input" type="number" 
                          value={this.state.TokenAmount || ''}
                          onChange={(e)=>this.setState({TokenAmount:e.target.value})}
                          onBlur={(e)=>this.getBnbApi(e.target.value,'token')}
                        />
                      </div>
                      <button data-toggle="modal" data-target="#buyModal" className="buy_btn" onClick={()=>this.setState({isBuy:false})}>{t('mainContent.Sell')}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="buyModal">
          <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal_logo">
              <img src="assets/img/logo_icon.png" alt="" />
            </div>
            <button type="button" className="close_btn" data-dismiss="modal"><img src="assets/img/Close-icon.png" alt="" /></button>
            <div className="modal-body">

            {this.state.isBuy ? (
              <>
                {/* <p className="received_text">{t('mainContent.Mintinghd')} {t('mainContent.Received')}: {parseFloat(this.state.get_mintinghd_amount).toFixed(7)}</p> */}
                <p className="received_text">{t('mainContent.Available_Balance')}: {parseFloat(this.state.availableStake).toFixed(7)} {t('mainContent.Mintinghd')}</p>
                <div className="form-group mb-2">
                  <label>Mintinghd {t('mainContent.Referral')}</label>
                  <input className="cus_input" type="text" placeholder={t('mainContent.Referral_Address')}
                    value={this.state.sponsor_address || ''}
                    onChange={(e)=>this.setState({sponsor_address:e.target.value})}
                  />
                  <input className="cus_input mt-4" type="number" placeholder={t('mainContent.Stake_Amount')}
                    value={this.state.stake_amount || ''}
                    onChange={(e)=>this.setState({stake_amount:e.target.value})}
                  />
                </div>                
                {/* <button className="buy_btn" onClick={this.doSwap}>{t('mainContent.Submit')}</button> */}
                <button className="buy_btn" onClick={this.doStake}>{t('mainContent.Submit')}</button>
              </>
            ) : (
              <>
                <p className="received_text">{t('mainContent.Mintinghd')} {t('mainContent.Received')}: {parseFloat(this.state.get_bnb_amount).toFixed(7)}</p>
                
                <div className="mb-3" style={{float:'right'}}>
                  <button className="buy_btn" onClick={this.doSwap}>{t('mainContent.Submit')}</button>
                </div>
              </>
            )}              
            </div>
            </div>
          </div>
        </div>
        
        
        <div className="service-area">
          <div className="container">
            <div className="table_box">
              <div className="table-responsive">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th>{t('mainContent.Stake')}</th>
                      <th>{t('mainContent.Reward')}</th>
                      <th>{t('mainContent.Unstake')}</th>
                      <th>{t('mainContent.Locked_Period')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.table_data.length ? (                        
                        this.state.table_data.length>0 ? (
                          this.state.table_data.map((val, index)=>{
                          let class_name = 'lebel_'+val.index;
                          if(val.level == 0){
                            class_name = 'current_user';
                          }
                    
                          return (
                            <tr key={`cl${index}`} className={class_name}>
                              <td>{parseFloat(val.stake).toFixed(2)}</td>
                              <td>{parseFloat(val.roi).toFixed(2)}</td>
                              <td><button  type="button" className="btn btn-outline-success btn-sm btn-block" onClick={()=>this.doUnstake(val.index,val.userStake_start)}>{t('mainContent.Unstake')}</button></td>
                              <td>{val.remainingDays} {val.remainingDays > 1 ? t('mainContent.Days') : t('mainContent.Day')}</td>
                            </tr>
                          )
                        })
                        ):(null)
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">{t('mainContent.No_Data')}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        
        <div className="about-area">
          <div className="container">
            <div className="main-title">
              <h3>{t('mainContent.My_Sponsor_List')}</h3>
            </div>
            <div className="table_box">
              <div className="table-responsive">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th>{t('mainContent.Level')}</th>
                      <th>{t('mainContent.Count')}</th>
                      <th>{t('mainContent.Purchased_Token')}</th>
                      <th>{t('mainContent.Bonus_Token')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {                      
                      this.state.sponsorList.length ? (                        
                        this.state.sponsorList.length>0 ? (
                          this.state.sponsorList.map((val, index)=>{
                          let class_name = 'lebel_'+val.index;
                          if(val.level == 0){
                            class_name = 'current_user';
                          }
                    
                          return (
                            <tr key={`sl${index}`} className={class_name}>
                              <td>{t('mainContent.Level')}-{val.level}</td>
                              <td>{val._noOfUser}</td>
                              <td>{val._investment} {t('mainContent.Mintinghd')}</td>
                              <td>{val.percentage_amount} {t('mainContent.Mintinghd')}</td>
                            </tr>
                          )
                        })
                        ):(null)
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">{t('mainContent.No_Data')}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="service-area" id="Service">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title">
                  <div className="sub-title">
                    <h5>{t('mainContent.Our_Services')}</h5>
                    <span></span>
                  </div>
                  <div className="main-title">
                    <h3>{t('mainContent.Our_Services_Heading')}</h3>
                    <p>{t('mainContent.Our_Services_Desc')}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="single-service-box">
                  <div className="service-content">
                    <div className="service-img">
                      <img src="assets/img/JJ.png" alt="" />
                    </div>
                    <div className="service-title">
                      <h3>{t('mainContent.Our_Service_Title1')}</h3>
                      <p>{t('mainContent.Our_Service_Desc1')}</p>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-service-box">
                  <div className="service-content">
                    <div className="service-img">
                      <img src="assets/img/2.png" alt="" />
                    </div>
                    <div className="service-title">
                      <h3>{t('mainContent.Our_Service_Title2')}</h3>
                      <p>{t('mainContent.Our_Service_Desc2')}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-service-box">
                  <div className="service-content">
                    <div className="service-img">
                      <img src="assets/img/3.png" alt="" />
                    </div>
                    <div className="service-title">
                      <h3>{t('mainContent.Our_Service_Title3')}</h3>
                      <p>{t('mainContent.Our_Service_Desc3')}</p>
                    </div>                    
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-service-box">
                  <div className="service-content">
                    <div className="service-img">
                      <img src="assets/img/4.png" alt="" />
                    </div>
                    <div className="service-title">
                      <h3>{t('mainContent.Our_Service_Title4')}</h3>
                      <p>{t('mainContent.Our_Service_Desc4')}</p>
                    </div>                    
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-service-box">
                  <div className="service-content">
                    <div className="service-img">
                      <img src="assets/img/5.png" alt="" />
                    </div>
                    <div className="service-title">
                      <h3>{t('mainContent.Our_Service_Title5')}</h3>
                      <p>{t('mainContent.Our_Service_Desc5')}</p>
                    </div>                    
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-service-box">
                  <div className="service-content">
                    <div className="service-img">
                      <img src="assets/img/6.png" alt="" />
                    </div>
                    <div className="service-title">
                      <h3>{t('mainContent.Our_Service_Title6')}</h3>
                      <p>{t('mainContent.Our_Service_Desc6')}</p>
                    </div>                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="road-map-area" id="Roadmap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title">
                  <div className="sub-title">
                    <h5>{t('mainContent.Roadmap')}</h5>
                    <span></span>
                  </div>
                  <div className="main-title">
                    <h3>{t('mainContent.Roadmap_Heading')}</h3>
                    <p>{t('mainContent.Roadmap_Desc')}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row map">
              <div className="col-lg-6 col-md-6">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="road-map-box">
                      <div className="road-content">
                        <div className="road-map-title">
                          <h4>Rinks Cooper</h4>
                          <span>Web Developer</span>
                          <p>Lorem ipsum dolor sit amet, consecteturadipiscing elit. Vivamus mattis justo idpulvinar suscipit. Pellen tesque rutrum vehicula</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="road-button-box">
                      <div className="road-content">
                        <div className="road-maps-button">
                          <a href="#">2019-2022</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="road-map-box">
                      <div className="road-content">
                        <div className="road-map-title">
                          <h4>Rinks Cooper</h4>
                          <span>Web Developer</span>
                          <p>Lorem ipsum dolor sit amet, consecteturadipiscing elit. Vivamus mattis justo idpulvinar suscipit. Pellen tesque rutrum vehicula</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="road-button-box">
                      <div className="road-content">
                        <div className="road-maps-button">
                          <a href="#">2019-2022</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="road-map-box">
                      <div className="road-content">
                        <div className="road-map-title">
                          <h4>Rinks Cooper</h4>
                          <span>Web Developer</span>
                          <p>Lorem ipsum dolor sit amet, consecteturadipiscing elit. Vivamus mattis justo idpulvinar suscipit. Pellen tesque rutrum vehicula</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="road-button-box">
                      <div className="road-content">
                        <div className="road-maps-button">
                          <a href="#">2019-2022</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="col-lg-12">
                  <div className="road-button-box">
                    <div className="road-content">
                      <div className="road-map-button upper">
                        <a href="#">2019-2022</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="road-map-box upper">
                    <div className="road-content">
                      <div className="road-map-title">
                        <h4>Rinks Cooper</h4>
                        <span>Web Developer</span>
                        <p>Lorem ipsum dolor sit amet, consecteturadipiscing elit. Vivamus mattis justo idpulvinar suscipit. Pellen tesque rutrum vehicula</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="road-button-box">
                    <div className="road-content">
                      <div className="road-map-button upper">
                        <a href="#">2019-2022</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="road-map-box upper">
                    <div className="road-content">
                      <div className="road-map-title">
                        <h4>Rinks Cooper</h4>
                        <span>Web Developer</span>
                        <p>Lorem ipsum dolor sit amet, consecteturadipiscing elit. Vivamus mattis justo idpulvinar suscipit. Pellen tesque rutrum vehicula</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="road-button-box">
                    <div className="road-content">
                      <div className="road-map-button upper">
                        <a href="#">2019-2022</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="road-map-box upper">
                    <div className="road-content">
                      <div className="road-map-title">
                        <h4>Rinks Cooper</h4>
                        <span>Web Developer</span>
                        <p>Lorem ipsum dolor sit amet, consecteturadipiscing elit. Vivamus mattis justo idpulvinar suscipit. Pellen tesque rutrum vehicula</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="shap_img">
                <img src="assets/img/map.png" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="feature-area">
          <div className="container">
            <div className="row ">
              <div className="col-lg-12">
                <div className="section-title">
                  <div className="sub-title">
                    <h5>{t('mainContent.Platform_Features')}</h5>
                    <span></span>
                  </div>
                  <div className="main-title">
                    <h3>{t('mainContent.Platform_Features_Heading')}</h3>
                    <p>{t('mainContent.Platform_Features_Desc')}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="single-feature-box">
                      <div className="feature-thumb">
                        <img src="assets/img/crypto-1.png" alt="" />
                      </div>
                      <div className="feature-content">
                        <div className="feature-title">
                          <h4>{t('mainContent.Privacy_Protection')}</h4>
                          <p>{t('mainContent.Privacy_Protection_Desc')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-feature-box upper">
                      <div className="feature-thumb">
                        <img src="assets/img/crypto-5.png" alt="" />
                      </div>
                      <div className="feature-content">
                        <div className="feature-title">
                          <h4>{t('mainContent.Easy_Management')}</h4>
                          <p>{t('mainContent.Easy_Management_Desc')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-feature-box">
                      <div className="feature-thumb">
                        <img src="assets/img/crypto-6.png" alt="" />
                      </div>
                      <div className="feature-content">
                        <div className="feature-title">
                          <h4>{t('mainContent.Digital_Transparency')}</h4>
                          <p>{t('mainContent.Digital_Transparency_Desc')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-tbm-box">
                  <div className="feature-tbm">
                    <img src="assets/img/trtyrt.png" alt="" />
                    <div className="shaps-tmb rotateme">
                      <img src="assets/img/shepe.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="single-features-box two">
                      <div className="feature-thumb">
                        <img src="assets/img/crypto-4.png" alt="" />
                      </div>
                      <div className="feature-content">
                        <div className="feature-title">
                          <h4>{t('mainContent.Decentralized_Platform')}</h4>
                          <p>{t('mainContent.Decentralized_Platform_Desc')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-features-box upper two">
                      <div className="feature-thumb">
                        <img src="assets/img/crypto-3.png" alt="" />
                      </div>
                      <div className="feature-content">
                        <div className="feature-title">
                          <h4>{t('mainContent.Trusted_Network')}</h4>
                          <p>{t('mainContent.Trusted_Network_Desc')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-features-box two">
                      <div className="feature-thumb">
                        <img src="assets/img/crypto-2.png" alt="" />
                      </div>
                      <div className="feature-content">
                        <div className="feature-title">
                          <h4>{t('mainContent.Premium_Platform')}</h4>
                          <p>{t('mainContent.Premium_Platform_Desc')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>        

      </Frame>
    );
  }
}

const useStyles = {
  root: {
    flexGrow: 1
  }
}

export default withRouter(withTranslation()(withStyles(useStyles)(withSnackbar(Home))));
//export default withRouter(withStyles(useStyles)(withSnackbar(Home)));